import React from 'react'
import Head from 'next/head'

import HomePageContent from '../content/HomePage/HomePage'
import getRecipeCategories from '../data/recipes/recipeCategories/recipeCategories.api/getRecipeCategories'
import getRecipes from '../data/recipes/recipes.api/getRecipes'
import { HomePagePropsProvider } from '../content/HomePage/useHomePageProps/useHomePageProps'

export default function HomePage(props) {
  const domain = 'https://hayletenjo.com/recipes'
  const title = 'Hayle Tenjo Recipes'
  const description = `Hayle's Recipes`
  const image = `${domain}/images/logo/logo.svg`
  const imageAlt = `Hayle tenjo logo`

  return (
    <>
      <Head>
        <title>Hayle Tenjo Recipes</title>
        <meta property='og:url' content={domain} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={image} />
        <meta property='og:image:alt' content={imageAlt} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={image} />
        <meta name='twitter:image:alt' content={imageAlt} />
      </Head>

      <HomePagePropsProvider pageProps={props}>
        <HomePageContent />
      </HomePagePropsProvider>
    </>
  )
}

export async function getStaticProps({ previewData }) {
  const recipes = await getRecipes({ previewData })
  const recipeCategories = await getRecipeCategories({
    previewData,
  })
  const recipeCategoriesWithRecipes = await getRecipeCategories({
    previewData,
    recipes,
    hideCategoriesWithoutRecipes: true,
  })

  return {
    props: {
      recipes,
      recipeCategories,
      recipeCategoriesWithRecipes,
    },
  }
}
