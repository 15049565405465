import React from 'react'
import Box from '@useweb/ui/Box'
import Text from '@useweb/ui/Text'

export type FilterUiProps = {
  title: string
  filters: {
    label: string
    value: string
  }[]
  onFilter: (options: { selectedFilter }) => any
}

export default function FilterUi({ title, filters = [], onFilter }: FilterUiProps) {
  return (
    <Wrapper>
      <Title title={title} />
      {/* <Filter filters={filters} onFilter={onFilter} /> */}
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: {
          xs: 'space-between',
          lg: 'start',
        },
        gap: '30px',
        alignItems: 'center',
        mb: {
          xs: '13px',
        },
      }}
    >
      {children}
    </Box>
  )
}

const Title = ({ title }) => {
  return (
    <Box>
      <Text
        tag='h2'
        text={title}
        sx={{
          fontSize: {
            xs: '16px',
            md: '20.67px',
          },
        }}
      />
    </Box>
  )
}

// const Filter = ({
//   filters = [],
//   onFilter = () => null,
// }: {
//   filters: FilterUiProps['filters']
//   onFilter: FilterUiProps['onFilter']
// }) => {
//   return (
//     <Select
//       defaultValue={filters[0].value}
//       name='recipe filter'
//       options={filters}
//       onChange={(selectedFilter) => onFilter({ selectedFilter })}
//       wrapperSx={{
//         width: '135px',
//       }}
//     />
//   )
// }
