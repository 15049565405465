import { useMemo } from 'react'

import RecipeListSchema from '../../../../../data/recipes/recipes.schema'
import useUrlSearchParams from '../../../../../lib/utils/url/useUrlSearchParams/useUrlSearchParams'

type UseRecipesListProps = { defaultRecipesList: RecipeListSchema[] }

export default function useRecipesList({ defaultRecipesList }: UseRecipesListProps) {
  const { recipeCategory, recipeFilter } = useUrlSearchParams()
  // TODO filter recipeFilter

  const filter = ({
    defaultRecipesList,
    recipeCategory,
  }: {
    defaultRecipesList: RecipeListSchema[]
    recipeCategory: string
  }) => {
    const filtered = !recipeCategory
      ? defaultRecipesList
      : defaultRecipesList.filter((r) => r.data.category.slug === recipeCategory)
    return filtered
  }

  const recipesList = useMemo(
    () => filter({ defaultRecipesList, recipeCategory }),
    [defaultRecipesList, recipeCategory],
  )

  return recipesList
}
