import React from 'react'
import { useRouter } from 'next/router'

import FilterUi from './FilterUi/Filter.ui'

export default function Filter() {
  const router = useRouter()

  const title = 'Recipes'
  const filters = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Members Only',
      value: 'membersOnly',
    },
    {
      label: 'Favorites',
      value: 'favorites',
    },
  ]

  const onFilter = ({ selectedFilter }) => {
    router.push(
      {
        pathname: '/',
        query: {
          ...router.query,
          recipeFilter: selectedFilter,
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  return <FilterUi title={title} filters={filters} onFilter={onFilter} />
}
