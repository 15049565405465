import React from 'react'
import Box from '@useweb/ui/Box'

import RecipesSchema from '../../../../../data/recipes/recipes.schema'

import Filter from './Filter/Filter'
import List from './List/List'

export type RecipesListUiProps = {
  recipesList: RecipesSchema[]
}

export default function RecipesListUi({ recipesList }: RecipesListUiProps) {
  return (
    <Wrapper>
      <Filter />
      <List recipesList={recipesList} />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        p: '20px',
        pt: '5px',
      }}
    >
      {children}
    </Box>
  )
}
