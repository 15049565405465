import React, { createContext, useContext } from 'react'

import type RecipeCategorySchema from '../../../data/recipes/recipeCategories/recipeCategories.schema'
import type RecipesSchema from '../../../data/recipes/recipes.schema'

type HomePageProps = {
  recipes: RecipesSchema[]
  recipeCategories: RecipeCategorySchema[]
  recipeCategoriesWithRecipes: RecipeCategorySchema[]
}

export const HomePagePropsContext = createContext<HomePageProps>(null as any)

export const HomePagePropsProvider = ({ children, pageProps }) => {
  return (
    <HomePagePropsContext.Provider
      value={{
        ...pageProps,
      }}
    >
      {children}
    </HomePagePropsContext.Provider>
  )
}

const useHomePageProps = () => useContext(HomePagePropsContext)

export default useHomePageProps
