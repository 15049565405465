import React from 'react'

import RecipeCategories from './containers/RecipeCategories/RecipeCategories'
import RecipesList from './containers/RecipesList/RecipesList'

export default function HomePageContent() {
  return (
    <>
      <RecipeCategories />
      <RecipesList />
    </>
  )
}
