import React, { useMemo } from 'react'

import useHomePageProps from '../../useHomePageProps/useHomePageProps'

import RecipeCategoriesUi from './RecipeCategoriesUi/RecipeCategories.ui'

export default function RecipeCategories() {
  const { recipeCategoriesWithRecipes = [] } = useHomePageProps()

  const recipeCategoriesSorted = useMemo(
    () => recipeCategoriesWithRecipes.sort((a, b) => a.data.order - b.data.order),
    [recipeCategoriesWithRecipes],
  )

  const onRecipeCategoryClick = ({ clickedRecipeCategory }) => {
    console.log({ clickedRecipeCategory })
  }

  return (
    <RecipeCategoriesUi
      recipeCategories={recipeCategoriesSorted}
      onRecipeCategoryClick={onRecipeCategoryClick}
    />
  )
}
