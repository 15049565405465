import React from 'react'

import useHomePageProps from '../../useHomePageProps/useHomePageProps'

import RecipesListUi from './RecipesListUi/RecipesList.ui'
import useRecipesList from './useRecipesList/useRecipesList'

export default function RecipesList() {
  const { recipes = [] } = useHomePageProps()
  const recipesList = useRecipesList({ defaultRecipesList: recipes })

  return <RecipesListUi recipesList={recipesList} />
}
