import React from 'react'

import type RecipesSchema from '../../../../../../data/recipes/recipes.schema'

import ListUi from './ListUi/List.ui'

type ListProps = {
  recipesList: RecipesSchema[]
}

export default function List(props: ListProps) {
  return <ListUi recipesList={props.recipesList} />
}
