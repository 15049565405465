import React from 'react'
import Box from '@useweb/ui/Box'
import Image from 'next/image'
import Text from '@useweb/ui/Text'

import type RecipesSchema from '../../../data/recipes/recipes.schema'
import List from '../../../lib/components/List/List'
import Link from '../../../lib/components/Link/Link'
import getRecipeUrlId from '../../../data/recipes/utils/getRecipeUrlId/getRecipeUrlId'

export type RecipesListProps = {
  recipesList: RecipesSchema[]
}

export default function RecipesList({ recipesList = [] }: RecipesListProps) {
  return (
    <Wrapper>
      <List
        data={recipesList}
        ListItemComponent={RecipeItem}
        enableAds
        sx={{
          justifyContent: 'start',
          gridGap: {
            xs: '10px',
            lg: '24px',
          },
          gridTemplateColumns: {
            xs: `repeat( 2, minmax(130px, 1fr) )`,
            sm: `repeat( 3, minmax(188px, 1fr) )`,
            md: `repeat( 4, minmax(188px, 1fr) )`,
          },
        }}
      />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return <Box>{children}</Box>
}

const RecipeItem = (recipe: RecipesSchema) => {
  return (
    <Link href={`/recipes/${getRecipeUrlId({ recipe })}`}>
      <Box
        sx={{
          position: 'relative',
          borderRadius: '5.29px',

          '& img': {
            borderRadius: '5.29px',
          },
          transition: '0.2s',
          '& span:first-of-type': {
            width: '100% !important',
          },
        }}
      >
        {recipe.data.coverImage.url ? (
          <Image
            src={recipe.data.coverImage.url}
            objectFit='cover'
            alt={`${recipe.data.title} cover image`}
            placeholder='blur'
            blurDataURL={recipe.data.coverImage.blurDataURL}
            width={233}
            height={326}
          />
        ) : (
          <Box
            sx={{
              bgcolor: 'pink.light',
              borderRadius: '5.29px',
              display: 'inline-block',
            }}
          />
        )}
        <Box
          sx={{
            backdropFilter: 'blur(10px) saturate(1)',
            background: 'rgba(255, 255, 255, 0.54)',
            position: 'absolute',
            inset: 'auto 10px 15px 10px',
            height: '75.62px',
            borderRadius: '4px',
            py: '5px',
            px: '7px',
            overflow: 'hidden',
          }}
        >
          <Text
            tag='h3'
            text={recipe.data.title}
            sx={{
              fontSize: {
                xs: '15px',
                md: '18px',
              },
            }}
          />
        </Box>
      </Box>
    </Link>
  )
}
