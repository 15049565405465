import React from 'react'
import Box from '@useweb/ui/Box'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Text from '@useweb/ui/Text'

import type RecipeCategorySchema from '../../../../../data/recipes/recipeCategories/recipeCategories.schema'

export type RecipeCategoriesUiProps = {
  recipeCategories: RecipeCategorySchema[]
  onRecipeCategoryClick: any
}

export default function RecipeCategoriesUi({
  recipeCategories = [],
}: RecipeCategoriesUiProps) {
  return (
    <Wrapper>
      <List recipeCategories={recipeCategories} />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'block',
        width: {
          lg: 'fit-content',
        },
        m: {
          lg: '0 auto',
        },
        mb: {
          lg: '50px',
        },
      }}
    >
      {children}
    </Box>
  )
}

const List = ({
  recipeCategories = [],
}: {
  recipeCategories: RecipeCategorySchema[]
}) => {
  return (
    <Box
      component={'ul'}
      sx={{
        display: 'grid',
        gap: '10px',
        gridAutoFlow: 'column',
        overflowX: 'auto',
        justifyContent: 'start',
        overscrollBehaviorInline: 'contain',
        pl: '20px',
        py: '20px',
      }}
    >
      {recipeCategories.map((category, index) => {
        const isLast = recipeCategories.length - 1 === index

        return (
          <ListItem
            key={category.id}
            isLast={isLast}
            name={category.data.name}
            backgroundImage={category.data.backgroundImage}
          />
        )
      })}
    </Box>
  )
}

const ListItem = ({
  name,
  backgroundImage,
  isLast,
}: {
  name: string
  backgroundImage: RecipeCategorySchema['data']['backgroundImage']
  isLast: boolean
}) => {
  const router = useRouter()
  const urlrecipeCategory = router.query?.recipeCategory
  const nameLowerCase = name.toLocaleLowerCase()

  let isActive = false

  if (urlrecipeCategory === nameLowerCase) {
    isActive = true
  }

  const onCategoryClick = () => {
    let url = {}

    if (urlrecipeCategory === nameLowerCase) {
      delete router.query.recipeCategory
      url = {
        pathname: '/',
        query: {
          ...router.query,
        },
      }
    } else {
      url = {
        pathname: '/',
        query: {
          ...router.query,
          recipeCategory: nameLowerCase,
        },
      }
    }

    router.push(url, undefined, {
      shallow: true,
    })
  }

  return (
    <>
      <Box
        component={'li'}
        onClick={onCategoryClick}
        sx={{
          borderRadius: '21px',
          position: 'relative',
          border: 'black solid 2px',
          boxShadow: '0px 4.22222px 35.8889px rgba(255, 122, 122, 0.10)',
          borderColor: isActive ? '#FF7272' : 'transparent',
          transition: '0.2s',
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
              borderColor: '#FF7272',
            },
          },
          overflow: 'hidden',
          userSelect: 'none',
          cursor: 'pointer',
          width: {
            xs: '102.22px',
            md: '150.05px',
          },
          height: {
            xs: '80.06px',
            md: '121.91px',
          },
        }}
      >
        <Text
          text={name}
          sx={{
            position: 'absolute',
            zIndex: 2,
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'pink.dark',
            fontSize: {
              xs: '15px',
              md: '20px',
            },
          }}
        />

        <Image
          src={backgroundImage.url}
          objectFit='cover'
          alt={`${name} background image`}
          placeholder={backgroundImage.blurDataURL ? 'blur' : 'empty'}
          blurDataURL={backgroundImage.blurDataURL && backgroundImage.blurDataURL}
          width={146}
          height={118}
        />

        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            zIndex: 1,
            backgroundColor: 'rgba(211, 211, 211, 0.80)',
            mixBlendMode: 'screen',
            borderRadius: '17px',
          }}
        />
      </Box>

      {isLast && <Box sx={{ width: '4px' }} />}
    </>
  )
}
