import React from 'react'
import Box from '@useweb/ui/Box'

import type RecipesSchema from '../../../../../../../data/recipes/recipes.schema'
import RecipesList from '../../../../../../../lib/components/RecipesList/RecipesList'

export type ListUiProps = {
  recipesList: RecipesSchema[]
}

export default function ListUi({ recipesList = [] }: ListUiProps) {
  return (
    <Wrapper>
      <RecipesList recipesList={recipesList} />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return <Box>{children}</Box>
}
